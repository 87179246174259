@import "../bootstrap-less/bootstrap";
@icon-font-path: "../fonts/";

.badge_np {
  display: inline-block;
  min-width: 10px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #7b0000;
  border-radius: 10px;
}

.pro_table td {
  text-align:center;
}